// ================================================================================================
//     File Name: under-maintenance.scss
//     Description: Under Maintenance pages custom scss
//     ----------------------------------------------------------------------------------------------
//    Item Name: Item Name: Modern Admin -Angular 11+ Bootstrap 4 Admin Dashboard Template
//    Version: 3.0
//     Author: PIXINVENT
//     Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

.bg-maintenance-image{
	background-image: url(/assets/images/backgrounds/bg-2.jpg);
	background-repeat: no-repeat;
    background-size: cover;
}

