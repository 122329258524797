/*=========================================================================================
	File Name: demo.scss
	Description: CSS used for demo purpose only. Remove this css from your project.
	----------------------------------------------------------------------------------------
	Item Name: Item Name: Modern Admin -Angular 11+ Bootstrap 4 Admin Dashboard Template
	Version: 3.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

.fonticon-container{
	> .fonticon-wrap{
		float: left;
		width: 60px;
		height: 60px;
		line-height: 4.8rem;
		text-align: center;
		// border: 2px solid $primary;
		border-radius: 0.1875rem;
		margin-right: 1rem;
		margin-bottom: 1.5rem;
		> i{
			font-size: 2.28rem;
			transition: all .2s ease-in-out;
		}
		&.youtube{
			width: 100px;
		}
	}
	&:hover{
		i{
			color: $primary;
			font-size: 2.9rem;
			transform: scale(1.3);
		}
	}

	>.fonticon-classname, >.fonticon-unit{
		display: block;
    	font-size: 1.5rem;
	    line-height: 1.2;
	}

	>.fonticon-unit{
		font-size: 1rem;
		font-style: italic;
	}
}

.scroll-example{
	padding: 0.5rem;
	position: relative;
	border: 2px solid darken($body-bg,10%);
	overflow: auto;

	.horz-scroll-content{
		width:1200px;
	}
}


.browser{
	background: #e0e0e0;
    border: 4px solid #e0e0e0;
    width: 100%;
    height: 12rem;
    padding-top: 20px;
    margin: 0 0 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.browser iframe {
    border: 0;
    background: #fff;
    height: 100%;
    width: 100%;
}

.loader-wrapper {
	height: 8em;
}

.maintenance-icon{
	font-size:4rem;
}

.animationIcon{
	right: 30px;
	bottom: 10px;
}
